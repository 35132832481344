import React from "react";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import ImageUploader from "./ImageUploader.jsx";

const App: React.FC = () => {
  return (
    <div className="App">
      <Container>
        <Typography variant="h1" gutterBottom>
          "Ani-what now?" - detect animals in images
        </Typography>
        <ImageUploader />
      </Container>
    </div>
  );
};

export default App;
