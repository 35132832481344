import React, { useState, useEffect } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import PhotoIcon from "@mui/icons-material/PhotoOutlined";

const getArticle = (str) =>
  "aeiou".includes(str[0].toLowerCase()) ? "an" : "a";

const formatSpecies = (species) => {
  species = species.replace("_", " ");
  return species;
};

const ImageUploader = () => {
  const [image, setImage] = useState(undefined);
  const [species, setSpecies] = useState(undefined);

  const onFileChange = (e) => {
    setSpecies(undefined);
    const fr = new FileReader();
    fr.onload = function (e) {
      setImage(e.target.result);
    };
    fr.readAsDataURL(e.target.files[0]);
  };

  // get species
  useEffect(() => {
    if (image) {
      fetch("/api/v1/predict", {
        method: "POST",
        body: JSON.stringify({ image }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((response) => setSpecies(response.species))
        .catch((error) => console.error("Error:", error));
    }
  }, [image]);

  const isLoading = image && !species;

  return (
    <div style={{ width: "100%", height: "50em", border: "dashed" }}>
      {isLoading ? <LinearProgress variant="query" /> : null}
      {species ? (
        <Typography align="center">{`This is ${getArticle(
          species,
        )} ${formatSpecies(species)}`}</Typography>
      ) : null}
      <Container style={{ height: "100%" }}>
        <input type="file" onChange={onFileChange} />
        {image ? (
          <img
            alt="selected"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
            src={image}
          />
        ) : (
          <React.Fragment>
            <PhotoIcon
              color="action"
              style={{ width: "100%", height: "25em" }}
            />
            <Typography align="center">
              upload your animal image here
            </Typography>
          </React.Fragment>
        )}
      </Container>
    </div>
  );
};

export default ImageUploader;
